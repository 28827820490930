import PostEdition from "app/components/organisms/editor/post/edition";
import PostHeader from "app/components/organisms/editor/post/header";
import PostSidebar from "app/components/organisms/editor/post/sidebar";
import { setFiles, setSkip, setWhere } from "app/stores/files";
import { useGetPostQuery } from "app/stores/post";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

export default function PostEdit() {
  const { key: type, lang: language, post: id, project } = useParams();

  // PARAMS
  const dispatch = useDispatch();

  // REQUEST
  const { isLoading, refetch } = useGetPostQuery({ id, language, project, type });

  useEffect(() => {
    return () => {
      dispatch(setSkip(0));
      dispatch(setWhere({}));
      dispatch(setFiles([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id && refetch) refetch();
  }, [id, type, language, project, refetch]);

  return (
    <div className="h-full flex flex-col relative">
      {/* HEADER */}
      <PostHeader />

      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="h-12 bg-blue-900" />
        <div className="-mt-12 custom-container flex-1 h-full items-start overflow-hidden flex flex-row gap-6">
          {/* EDITION */}
          <PostEdition loading={isLoading} />

          {/* SIDEBAR */}
          <PostSidebar loading={isLoading} />
        </div>
        <div className="h-6" />
      </div>
    </div>
  );
}
