import { i18n } from "app/i18n";
import customValidator from "app/utils/validators/customValidator";
import validator from "validator";

export const components = [
  {
    type: "Text",
    key: "title",
    title: i18n("label.title"),
    onBlur: { key: "key", lower: true },
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    type: "Slug",
    key: "key",
    title: i18n("label.key"),
    options: { lower: false },
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
      {
        method: customValidator.isBlockKeyAvailable,
        validWhen: true,
        args: {
          project: undefined,
          language: undefined,
        },
        message: i18n("input.slug_in_use"),
      },
    ],
  },
  {
    type: "Toggle",
    key: "copy",
    title: i18n("label.copy_content"),
    description: i18n("label.copy_content_description"),
  },
  {
    type: "Select",
    key: "origin",
    options: [],
    title: i18n("label.language"),
    description: i18n("label.language_description"),
    conditional: {
      field: "copy",
      when: "isTrue",
    },
  },
  {
    type: "Toggle",
    key: "translate",
    title: "Translate Content",
    description: "Automatic translate content using Google Translate",
    conditional: {
      field: "copy",
      when: "isTrue",
    },
  },
  {
    type: "Select",
    key: "contentType",
    title: i18n("label.contentType"),
    options: [],
    rules: [
      {
        method: customValidator.isObjectEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
];
