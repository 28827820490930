import { Content, Footer, Header, Struture } from "app/components/atoms/modal";
import Form from "app/components/organisms/form";
import { i18n } from "app/i18n";
import { cleanStructure } from "app/utils/content";
import { getFlag } from "app/utils/languages";
import { validation } from "app/utils/validators";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import slugify from "slugify";
import { components, input, inputImage, inputKey } from "./constants";

export default function ModalCategoryType(props) {
  const { category, image, isOpen = false, onSave, isLoading, onDelete, onClose = () => {} } = props;

  // STORE
  const { languages } = useSelector((store) => store.project);

  // STATE
  const [value, setValue] = useState(null);
  const [structure, setStructure] = useState(null);

  useEffect(() => {
    if (isOpen) {
      buildStructure();
      fillForm();
    } else {
      setValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const buildStructure = () => {
    let tempStructure = cleanStructure(components);
    if (category) {
      tempStructure.unshift(inputKey);
    }
    if (image) {
      tempStructure.splice(category ? 1 : 0, 0, inputImage);
    }
    if (languages?.length) {
      const index = tempStructure.findIndex((e) => e.key === "translations");
      if (index !== -1) {
        tempStructure[index].children = [];
        for (let i = 0; i < languages.length; i++) {
          tempStructure[index].children.push({
            ...input,
            key: languages[i].language.slug,
            title: `${getFlag(languages[i].language.slug)} ${languages[i].language.name}`,
          });
        }
      }
    }
    setStructure([...tempStructure]);
  };

  const fillForm = () => {
    if (category) {
      let key = category.key;
      let thumbnail = category.thumbnail;
      let translations = undefined;
      if (languages?.length) {
        translations = {};
        for (let i = 0; i < languages.length; i++) {
          const value = category.translations.find((e) => e.language.slug === languages[i].language.slug);
          translations[languages[i].language.slug] = value?.value || "";
        }
      }
      setValue({ key, thumbnail, translations });
    }
  };

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) {
        const thumbnail = value?.thumbnail?.id ? { connect: { id: value.thumbnail.id } } : value?.thumbnail?.disconnect ? value?.thumbnail : undefined;
        const key = slugify(value?.translations[languages.find((e) => e.default).language.slug], { replacement: "-", lower: true, trim: true, remove: /[*+~.()'"!:@]/g });
        onSave({ key, thumbnail, translations: languages.map((e) => ({ value: value?.translations[e.language.slug] || "", language: e.language.slug })) });
      }
    });
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={i18n("label.update_category")} onClose={onClose} />
      <Content>
        <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} />
      </Content>
      <Footer
        loading={isLoading}
        cancel={{ text: i18n("button.close"), onClick: onClose }}
        clean={{ text: i18n("button.delete"), onClick: onDelete, permission: { min: "MANAGER" } }}
        submit={{ text: i18n("button.save"), disabled: isLoading, onClick: onClickSubmit }}
      />
    </Struture>
  );
}
