const components = [
  {
    type: "Toggle",
    title: "Optimize",
    key: "optimize",
  },
  {
    type: "Range",
    title: "Quality",
    description: "Default image quality", // TO-DO
    key: "quality",
    min: 0,
    step: "10",
    max: 100,
  },
  {
    type: "Numeric",
    title: "Max Width",
    description: "For images, sets a maximum size", // TO-DO
    key: "maxWidth",
    max: 3600,
    min: 1024,
  },
  {
    type: "Toggle",
    title: "Customized path",
    description: "Customize multimedia path in frontend",
    key: "customized",
  },
  {
    type: "Text",
    title: "Path",
    key: "customPath",
    conditional: {
      field: "customized",
      when: "isTrue",
    },
  },
];

export { components };
