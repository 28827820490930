import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Form from "app/components/organisms/form";
import { useGetTypeCategoriesQuery } from "app/stores/inputs";
import { cleanStructure } from "app/utils/content";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function SelectCategory(props) {
  const { title, description, value, error, errorMessage, mode, isRequired, onChange } = props;

  let styles = {
    title: "block text-sm font-medium mb-1",
    box: "px-5 pt-5 pb-3 bg-gray-50 rounded-lg border dynamic-content",
  };

  const components = [
    {
      type: "Select",
      key: "type",
      title: "Post Type",
      placeholder: "Filtrar por Post Type",
      options: [],
      multiple: false,
    },
    {
      type: "Select",
      key: "category",
      title: "Category Type",
      placeholder: "Filtrar por Post Type",
      options: [],
      disabled: true,
    },
    {
      type: "Select",
      key: "option",
      title: "Option",
      placeholder: "Filtrar por Post Type",
      options: [],
      disabled: true,
      clearable: true,
    },
    {
      type: "Toggle",
      title: "Include count",
      key: "includeCount",
    },
  ];

  // STORE
  const { types } = useSelector((state) => state.inputs);
  const { language } = useSelector((state) => state.project);

  // VARS
  const selectedType = value?.type?.value;
  const selectedCategory = value?.category?.value;
  const selectedOption = value?.option?.value;

  // QUERY
  const { data: categories } = useGetTypeCategoriesQuery({ id: selectedType }, { skip: !selectedType });

  // STATE
  const [structure, setStructure] = useState(components);

  // SET TYPES
  useEffect(() => {
    if (types?.length || categories) {
      const temp = cleanStructure(components);

      if (types?.length) {
        const index = temp.findIndex((e) => e.key === "type");
        if (index !== -1) if (!temp[index]?.options?.length) temp[index].options = types;
      }
      if (categories) {
        const index = temp.findIndex((e) => e.key === "category");
        if (index !== -1) {
          temp[index].disabled = false;
          temp[index].options = categories.map((e) => ({ value: e.id, label: e.title }));
        }
      }
      if (categories?.length && selectedCategory) {
        const index = temp.findIndex((e) => e.key === "option");
        if (index !== -1) {
          temp[index].disabled = false;
          temp[index].options = categories
            ?.find((e) => e.id === selectedCategory)
            ?.options.map((e) => ({ value: e.id, label: e.translations.find((t) => t.language.slug === language.slug)?.value || "???" }));
        }
      }

      setStructure([...temp]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types, categories, selectedType, selectedCategory]);

  useEffect(() => {
    if (selectedType && selectedCategory && categories) {
      // CLEAN CATEGORY IF NECESSARY
      if (!categories.find((e) => e.id === selectedCategory)) onChange({ type: value?.type });

      // CLEAN OPTION IF NECESSARY
      if (selectedCategory && selectedOption) {
        const options = categories.find((e) => e.id === selectedCategory)?.options || [];
        if (!options.find((e) => e.id === selectedOption)) onChange({ type: value?.type, category: value?.category });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType, selectedCategory, selectedOption, categories]);

  if (error) {
    styles.box += " border-red-600";
    styles.title += " text-red-600";
  } else {
    styles.box += " border-transparent";
    styles.title += " text-slate-700";
  }

  return (
    <div className={`block mb-3 ${mode ? mode : ""}`}>
      <div className="flex items-end justify-between">
        <div>
          {title && <span className={styles.title}>{title}</span>}
          {description && <span className="block text-xs font-light text-slate-400 -mt-1.5 mb-1">{description}</span>}
        </div>
        {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 mb-1 ${error ? "text-red-600" : "text-slate-700"}`} />}
      </div>
      <div className={styles.box}>
        <Form value={value} structure={structure} onChange={onChange} className="flex gap-4" />
      </div>
      {error && <p className="mt-1 text-xs text-red-600">{errorMessage}</p>}
    </div>
  );
}
