import IconPlus from "app/components/atoms/icons/iconPlus";
import Loading from "app/components/atoms/loading";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ModalForm from "app/components/molecules/modals/modalForm";
import Permission from "app/components/molecules/permission";
import ListTable from "app/components/molecules/table";
import { i18n } from "app/i18n";
import { setPage, setWhere, useCreateFormMutation, useCreateFormTranslationMutation, useDeleteFormMutation, useDuplicateFormMutation, useGetFormsQuery, useGetFormsTotalQuery } from "app/stores/forms";
import { getFlag } from "app/utils/languages";
import { showServerError, showServerSuccess } from "app/utils/server";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ListSubmissions from "../listSubmissions";
import { headers } from "./constants";
import ItemForm from "./itemForm";

export default function ListForms() {
  // PARAMS
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // STORE
  const { take, skip, where, orderBy } = useSelector((state) => state.form);
  const { project, language, languages } = useSelector((state) => state.project);

  // STATE
  const [modal, setModal] = useState(null);
  const [formDetail, setFormDetail] = useState(null);
  const [postsHeaders, setPostsHeaders] = useState(headers);
  const [confirmModal, setConfirmModal] = useState(null);

  // REQUESTS
  const { data: forms, refetch: updateList } = useGetFormsQuery({ take, skip, where, orderBy });
  const { data: total, refetch: updateTotal } = useGetFormsTotalQuery({ where });

  // MUTATION
  const [createForm] = useCreateFormMutation();
  const [deleteForm] = useDeleteFormMutation();
  const [duplicateForm] = useDuplicateFormMutation();
  const [createFormTranslation] = useCreateFormTranslationMutation();

  const isLoading = false;

  useEffect(() => {
    if (project) dispatch(setWhere({ project: { id: project.id } }));
  }, [project]);

  useEffect(() => {
    if (languages?.length && headers?.length) {
      const temp = [...headers];
      const index = temp.findIndex((item) => item.isLang);
      if (index !== -1) temp[index].options = languages.map((item) => item.language.slug);
      setPostsHeaders([...temp]);
    }
  }, [languages]);

  const onCreate = () => {
    setModal({
      isOpen: true,
      onSubmit: ({ title }) => {
        createForm({ title, project: project.id, language: language.id }).then((res) => {
          if (!res.error && res.data) {
            navigate(`${res.data.id}/${language.slug}`);
            updateList();
            updateTotal();
            showServerSuccess(i18n("toast.success_create"));
          } else {
            showServerError(res);
          }
        });
      },
      onClose: () => {
        setModal(false);
      },
    });
  };

  const onCreateTranslation = (form, language) => {
    const availableTranslations = languages
      .filter((e) => form.translations.find((content) => content.language.slug === e.language.slug))
      .map((e) => {
        const title = form.translations.find((content) => content.language.slug === e.language.slug).title;
        return { value: e.language.id, label: `<span className="mr-1">${getFlag(e.language.slug)}</span> ${title}` };
      });

    setModal({
      isOpen: true,
      duplicate: true,
      availableTranslations,
      onSubmit: ({ title, copy, language: origin, translate }) => {
        createFormTranslation({ form: form.id, language: language.language.id, title, copy, origin: origin.value, translate }).then((res) => {
          if (!res.error && res.data) {
            updateList();
            navigate(`${res.data.id}/${language.language.slug}`);
            showServerSuccess(i18n("toast.success_duplicate"));
          } else {
            showServerError(res);
          }
        });
      },
      onClose: () => setModal(null),
    });
  };

  // DUPLICATE FORM
  const onDuplicate = (form) => {
    setConfirmModal({
      isOpen: true,
      title: "Duplicar",
      text: "Deseja duplicar este formulário?",
      onClose: () => setConfirmModal(null),
      onConfirm: () => {
        duplicateForm({ form: form.id, project: project.id, language: language.id }).then((res) => {
          if (!res.error && res.data) {
            navigate(`/project/${project.id}/forms/${res.data.id}/${language.slug}`);
            updateList();
            updateTotal();
            showServerSuccess(i18n("toast.success_duplicate"));
          } else {
            showServerError(res);
          }
        });
      },
    });
  };

  // DELETE FORM
  const onDelete = (form) => {
    setConfirmModal({
      isOpen: true,
      type: "DELETE",
      mode: "INPUTS",
      inputs: [
        {
          type: "Toggle",
          title: "Delete Form",
          key: "complete",
          description: "With this action active, you will delete all translations of this form",
          texts: [i18n("label.delete_full_form"), i18n("label.delete_just_translation")],
        },
      ],
      forceNotice: true,
      notice: "Delete this form may cause damage to the website's functionality. Proceed with caution",
      onClose: () => setConfirmModal(null),
      onConfirm: (values) => {
        deleteForm({ id: form.id, project: project.id, language: language.id, complete: !!values?.complete }).then((res) => {
          if (res.data) {
            updateList();
            updateTotal();
            showServerSuccess(i18n("toast.success_delete"));
          } else {
            showServerError(res);
          }
          setConfirmModal(null);
        });
      },
    });
  };

  // OPEN MODAL WITH SUBMISSIONS
  const onSeeSubmissions = (form) => {
    setFormDetail({
      form,
      isOpen: true,
      onClose: () => setFormDetail(null),
    });
  };

  return (
    <>
      <div className="flex flex-1 relative">
        <div className="custom-container px-10">
          <div className="flex items-center justify-between pt-16 pb-10">
            <span className="flex items-center">
              <h1 className="text_3xl">{i18n("label.forms")}</h1>
              <Loading active={isLoading} className="ml-3" />
            </span>
            {project && (
              <Permission min="ADMINISTRATOR" dev>
                <button type="button" className="btn-primary-outline" onClick={onCreate}>
                  <IconPlus />
                  {i18n("button.create_form")}
                </button>
              </Permission>
            )}
          </div>
          <div className="h-full">
            <ListTable headers={postsHeaders} setPage={setPage} store="form" total={total}>
              {!!forms?.length &&
                forms.map((form) => (
                  <ItemForm
                    {...form}
                    key={form.id}
                    onDelete={() => onDelete(form)}
                    onDuplicate={() => onDuplicate(form)}
                    onSeeSubmissions={() => onSeeSubmissions(form)}
                    onCreateTranslation={(language) => onCreateTranslation(form, language)}
                  />
                ))}
            </ListTable>
          </div>
        </div>
      </div>

      <ModalForm {...modal} isLoading={isLoading} />
      <ModalConfirm {...confirmModal} isLoading={isLoading} />
      <ListSubmissions {...formDetail} updateList={updateList} />
    </>
  );
}
