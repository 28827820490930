import Icon from "app/components/atoms/icons/icon";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { loadOptions } from "./functions";
import { useEffect, useState } from "react";
import { setPosts } from "app/stores/inputs";

export default function SelectLoadMore(props) {
  const { value, posttype, multiple, placeholder, disabled, options, error, onChange } = props;

  // PARAMS
  const dispatch = useDispatch();
  const { lang } = useParams();

  // STORE
  const { types } = useSelector((state) => state.inputs);
  const { project, languages } = useSelector((state) => state.project);

  // STATE
  const [language, setLanguage] = useState(null);

  // VARS
  const total = posttype && types.find((e) => e.value === posttype) ? types.find((e) => e.value === posttype).total : 0;

  useEffect(() => {
    if (languages?.length) {
      const validLanguage = lang ? languages?.find((l) => l?.language?.slug === lang) : languages?.find((l) => l?.default);
      if (validLanguage?.language) {
        setLanguage(validLanguage.language);
        dispatch(setPosts([]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, languages]);

  return (
    <div className={`input-loadmore__outer${multiple ? " input-loadmore__outer--is-multi" : ""}${error ? " input-loadmore__outer--error" : ""}`}>
      {language?.id && (
        <AsyncPaginate
          value={value}
          options={options}
          key={language?.id}
          isMulti={multiple}
          onChange={onChange}
          isDisabled={disabled}
          placeholder={placeholder}
          closeMenuOnSelect={!multiple}
          classNamePrefix="input-loadmore"
          loadOptions={(search, options) => loadOptions(search, options, { id: posttype, total }, language, project, dispatch)}
        />
      )}
      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        <Icon name="chevronUpDown" className="text-gray-400" />
      </span>
    </div>
  );
}
