import dayjs from "dayjs";
import { request, requestAuth } from "../server";

const customValidator = {
  isUndefinedOrNull: (value) => {
    return typeof value === "undefined" && value === null;
  },
  isObjectEmpty: async (value) => {
    return typeof value !== "object" && value.length === 0;
  },
  isValidDate: async (value) => {
    return value && dayjs(value).isValid();
  },
  isPasswordStrong: async (value) => {
    if (value.length === 0) {
      return false;
    }

    const matchedCase = [];
    matchedCase.push("[$@$!%*#?&_(){}=]"); // Special Character
    matchedCase.push("[A-Z]"); // Uppercase Characters
    matchedCase.push("[0-9]"); // Numbers Character
    matchedCase.push("[a-z]"); // Lowercase Characters

    let ctr = 0;
    for (let i = 0; i < matchedCase.length; i++) {
      if (new RegExp(matchedCase[i]).test(value)) {
        ctr++;
      }
    }

    return ctr === 4 && value.length > 8;
  },
  isPasswordCurrentAndValid: async (value, args) => {
    const response = await requestAuth(
      `
        query userPasswordConfirm ($id: String!, $password: String!){
          userPasswordConfirm(id: $id, password: $password)
        }
      `,
      {
        password: value,
        id: args ? args.id : undefined,
      }
    );
    return response.data ? response.data.userPasswordConfirm : false;
  },
  isEqualTo: async (value, args, state) => {
    if (!value.length) return false;
    if (!args || !args.key) return false;
    if (!state || !state[args.key]) return false;
    return value === state[args.key];
  },
  isEmailAvailable: async (value, args) => {
    const response = await request(
      `
        query userEmailAvailable ($id: ID, $email: String!){
          userEmailAvailable(id: $id, email: $email)
        }
      `,
      {
        email: value,
        id: args ? args.id : undefined,
      }
    );
    return response.data ? response.data.userEmailAvailable : false;
  },
  isValidLatitude: async (value) => {
    const latPattern = /^-?([1-8]?[0-9](\.\d+)?|90(\.0+)?)$/;
    return latPattern.test(value);
  },
  isValidLongitude: async (value) => {
    const lngPattern = /^-?((1[0-7][0-9]|[1-9]?[0-9])(\.\d+)?|180(\.0+)?)$/;
    return lngPattern.test(value);
  },
  isProjectSlugAvailable: async (value, args) => {
    const response = await request(
      `
        query projectSlugAvailable ($id: ID, $slug: String!){
          projectSlugAvailable(id: $id, slug: $slug)
        }
      `,
      {
        slug: value,
        id: args ? args.id : undefined,
      }
    );
    return response.data ? response.data.projectSlugAvailable : false;
  },
  isSectionKeyAvailable: async (value, args) => {
    const response = await requestAuth(
      `
        query sectionKeyAvailable ($id: ID, $key: String!){
          sectionKeyAvailable(id: $id, key: $key)
        }
      `,
      {
        key: value,
        id: args ? args.id : undefined,
      }
    );
    return response.data ? response.data.sectionKeyAvailable : false;
  },
  isPostTypeKeyAvailable: async (value, args) => {
    const response = await requestAuth(
      `
        query postTypeKeyAvailable ($id: ID, $key: String!){
          postTypeKeyAvailable(id: $id, key: $key)
        }
      `,
      {
        key: value,
        id: args ? args.id : undefined,
      }
    );
    return response.data ? response.data.postTypeKeyAvailable : false;
  },
  isContentTypeKeyAvailable: async (value, args) => {
    const response = await requestAuth(
      `
        query contentTypeKeyAvailable ($id: ID, $key: String!){
          contentTypeKeyAvailable(id: $id, key: $key)
        }
      `,
      {
        key: value,
        id: args ? args.id : undefined,
      }
    );
    return response.data ? response.data.contentTypeKeyAvailable : false;
  },
  isPageSlugAvailable: async (value, args) => {
    if (!args || !args.type || !args.project || !args.language) return false;
    const response = await requestAuth(
      `
        query pageSlugAvailable ($id: ID, $type: String!, $project: String!, $slug: String!, $language: String!){
          pageSlugAvailable(id: $id, type: $type, project: $project, slug: $slug, language: $language)
        }
      `,
      {
        slug: value,
        language: args ? args.language : null,
        type: args ? args.type : null,
        project: args ? args.project : null,
        id: args ? args.id : undefined,
      }
    );
    return response.data ? response.data.pageSlugAvailable : false;
  },
  isBlockKeyAvailable: async (value, args) => {
    if (!args || !args.language || !args.project) return false;
    const response = await requestAuth(
      `
        query blockKeyAvailable ($id: ID, $key: String!, $project: String!, $language: String!){
          blockKeyAvailable(id: $id, key: $key, project: $project, language: $language)
        }
      `,
      {
        key: value,
        language: args ? args.language : null,
        project: args ? args.project : null,
        id: args ? args.id : undefined,
      }
    );
    return response.data ? response.data.blockKeyAvailable : false;
  },
  isTranslationKeyAvailable: async (value, args) => {
    const response = await requestAuth(
      `
        query translationKeyAvailable ($id: ID, $key: String!, $project: String!){
          translationKeyAvailable(id: $id, key: $key, project: $project)
        }
      `,
      {
        key: value,
        id: args ? args.id : undefined,
        project: args ? args.project : undefined,
      }
    );
    return response.data ? response.data.translationKeyAvailable : false;
  },
};

export default customValidator;
