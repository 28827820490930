import Icon from "app/components/atoms/icons/icon";
import { Content, Header, Struture } from "app/components/atoms/modal";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import Permission from "app/components/molecules/permission";
import { i18n } from "app/i18n";
import { useDeleteFormSubmissionMutation, useGetSubmissionsQuery, useMarkAsReadMutation } from "app/stores/forms";
import { formatDifference } from "app/utils/content";
import { showServerError, showServerSuccess } from "app/utils/server";
import { isArray, isObject } from "app/utils/validators/dataValidator";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function ListSubmissions(props) {
  const { form, isOpen = false, updateList, onClose = () => {} } = props;

  // STORE
  const { project, language } = useSelector((store) => store.project);

  // STATE
  const [page, setPage] = useState(1);
  const [detail, setDetail] = useState(null);
  const [modalConfirm, setModalConfirm] = useState(null);

  // VARIABLES
  const id = form?.id;
  const total = form?.unReadCount || 0;
  const info = language && form?.translations?.length ? form?.translations?.find((item) => item.language.slug === language.slug) : undefined;
  const structure = info?.structure;
  const emailField = isArray(structure, true) ? structure.find((e) => e.emailField) : undefined;

  // PARAMS
  const take = page * 10;
  const where = { form: { form: { id } } };
  const orderBy = { createdAt: "desc" };

  // REQUEST
  const { data, refetch } = useGetSubmissionsQuery({ take, where, orderBy }, { skip: !id });

  // MUTATION
  const [markAsRead] = useMarkAsReadMutation();
  const [deleteSubmission] = useDeleteFormSubmissionMutation();

  useEffect(() => {
    if (id) setPage(1);
  }, [id]);

  // FUNCTIONS
  const onOpenDetail = (item) => setDetail(item);
  const onCloseDetail = () => setDetail(false);

  // MARK SUBMISSION AS READ
  const onRead = (id) => {
    markAsRead({ id, read: true }).then((res) => {
      if (res.data) {
        refetch();
        setDetail({ ...detail, read: true });
      } else {
        showServerError(res);
      }
    });
  };

  // DELETE SUBMISSION
  const onDelete = (id) => {
    setModalConfirm({
      isOpen: true,
      type: "DELETE",
      text: "Do you want to delete this submission?",
      onConfirm: () => {
        deleteSubmission({ id, project: project.id }).then((res) => {
          if (res.data) {
            refetch();
            updateList();
            setDetail(null);
            showServerSuccess(i18n("toast.submission_deleted"));
          } else {
            showServerError(res);
          }
          setModalConfirm(null);
        });
      },
      onClose: () => setModalConfirm(null),
    });
  };

  return (
    <>
      {/* LIST */}
      <Struture rightSide isOpen={isOpen} onClose={onClose}>
        <Header rightSide onClose={onClose} title={i18n("label.submissions")} subtitle={`${info?.title} - ${total} ${total === 1 ? i18n("label.submission") : i18n("label.submissions")}`} />
        <Content rightSide>
          <div className="relative flex-1 max-h-full overflow-y-auto">
            {!!data?.length ? (
              <>
                <div className="space-y-1 cursor-pointer divide-y mb-10">
                  {data.map((item, key) => {
                    const email = emailField?.slug ? item.values[emailField?.slug] : undefined;
                    return (
                      <button
                        key={key}
                        type="button"
                        onClick={() => onOpenDetail(item)}
                        className="flex justify-between transition-all duration-300 ease-linear w-full group items-center py-2 text-sm font-medium text-gray-600 hover:text-black"
                      >
                        <div className="flex flex-col items-start">
                          <p>{email}</p>
                          <p className="text-xs text-gray-300">
                            {item.read ? <span className="text-lime-700">{i18n("label.read")}</span> : <span className="text-red-700">{i18n("label.unread")}</span>}
                            {item?.form?.language?.slug ? ` - ${item?.form?.language?.slug?.toUpperCase()} - ` : " - "}
                            {formatDifference(item.createdAt)}
                          </p>
                        </div>
                        <Icon name="chevronRight" />
                      </button>
                    );
                  })}
                </div>
                {data.length < total && (
                  <>
                    <button
                      type="button"
                      onClick={() => setPage(page + 1)}
                      className="border rounded-lg text-xs bg-white px-4 py-2 w-full mb-10 transition-all duration-500 ease-linear hover:bg-slate-100"
                    >
                      {i18n("label.loadmore")}
                    </button>
                  </>
                )}
              </>
            ) : (
              <>
                <p className="text-sm text-center">{i18n("label.nothing_found")}</p>
              </>
            )}
          </div>
        </Content>
      </Struture>

      {/* DETAIL */}
      <Struture rightSide isOpen={!!detail} onClose={onCloseDetail}>
        <Header rightSide onClose={onCloseDetail} title={i18n("label.submission")} subtitle={i18n("label.submission_detail")} />
        <Content rightSide>
          <div>
            {!!structure?.length && detail && (
              <>
                <table className="w-full mb-6">
                  <tbody>
                    {structure.map((e, key) => {
                      const values = detail?.values;
                      let value = values ? values[e.slug] : null;

                      // FORMAT BY TYPE
                      if (e.type === "checkbox") value = value ? i18n("label.yes") : i18n("label.no");
                      // JOIN OPTIONS OF ARRAY
                      if (isArray(value, true)) value = value.join("<br/>");
                      if (isObject(value)) value = value?.label;

                      return (
                        <tr key={key}>
                          <td className="border p-1 pr-2 w-1/2 font-semibold text-xs align-top">{e.name}</td>
                          <td className="border p-1 pr-2 w-1/2 text-xs" dangerouslySetInnerHTML={{ __html: value }} />
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <div className="my-6">
                  <h4 className="text-sm font-medium">{i18n("label.submission_date")}</h4>
                  <p className="text-xs text-slate-500">{dayjs(detail.createdAt).format("DD/MM/YYYY HH:mm")}</p>
                </div>

                <div className="my-6">
                  <h4 className="text-sm font-medium">{i18n("label.submission_status")}</h4>
                  <p className="text-xs text-slate-500">{detail.read ? i18n("label.read") : i18n("label.unread")}</p>
                </div>

                <div className="flex flex-col gap-4 mt-6">
                  {!detail.read && (
                    <Permission project dev min="EDITOR">
                      <button
                        type="button"
                        className="flex-1 px-3 py-2 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => onRead(detail.id)}
                      >
                        {i18n("button.mark_as_read")}
                      </button>
                    </Permission>
                  )}

                  <Permission project dev min="MANAGER">
                    <button
                      type="button"
                      onClick={() => onDelete(detail.id)}
                      className="flex-1 px-3 py-2 text-sm font-semibold transition-all duration-300 ease-linear text-white bg-red-600 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-400"
                    >
                      {i18n("button.delete")}
                    </button>
                  </Permission>
                </div>
              </>
            )}
          </div>
        </Content>
      </Struture>

      <ModalConfirm {...modalConfirm} />
    </>
  );
}
