import React from "react";
import Icon from "../../icons/icon";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export default function Numeric(props) {
  const { value, max = 99999, min = -999999, title, description, clearable, error, errorMessage, disabled, isRequired, onChange } = props;

  const styles = {
    title: "block text-sm font-medium mb-1",
    description: "block text-xs font-light text-slate-400 -mt-1.5 mb-1",
    box: "flex flex-row h-[42px] w-full bg-gray-50 border rounded-lg relative overflow-hidden",
    input: "custom-number-input",
  };

  if (value?.toString()?.length > 3) styles.input += " w-32";
  else styles.input += " w-28";

  function decrement() {
    if (disabled) return;
    if (!value) onChange(-1);
    else if (parseInt(value) > parseInt(min, 10)) onChange(parseInt(value) - 1);
  }

  function increment() {
    if (disabled) return;
    if (!value) onChange(1);
    else if (parseInt(value) < parseInt(max, 10)) onChange(parseInt(value) + 1);
  }

  function onInputChange(e) {
    if (disabled) return;
    const newValue = parseInt(e.target.value) || 0;
    if (newValue <= max && newValue >= min) onChange(newValue);
    else if (newValue > max) onChange(max);
    else if (newValue < min) onChange(min);
  }
  function inputValue() {
    if (value === "0") return "0";
    else if (typeof value === "undefined") return "";
    else if (value === null) return "";
    return value;
  }
  if (error) {
    styles.title += " text-red-600";
    styles.box += " border-red-600";
  } else {
    styles.title += " text-slate-700";
    styles.box += " border-gray-300";
  }
  return (
    <div className="block mb-3">
      <div className="flex items-end justify-between">
        <div>
          {title && <label className={styles.title}>{title}</label>}
          {description && <span className={styles.description}>{description}</span>}
        </div>
        {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 mb-1 ${error ? "text-red-600" : "text-slate-700"}`} />}
      </div>
      <div className="flex items-center">
        <div className={styles.input}>
          <div className={styles.box}>
            <button
              onClick={decrement}
              disabled={disabled}
              data-action="decrement"
              className="bg-gray-50 text-slate-400 h-full w-20 cursor-pointer outline-none transition-all duration-300 ease-linear disabled:pointer-events-none hover:text-slate-700 hover:bg-gray-200 disabled:text-slate-200"
            >
              <span className="m-auto text-2xl font-thin">−</span>
            </button>
            <input
              min={min}
              max={max}
              type="number"
              disabled={disabled}
              value={inputValue()}
              onChange={onInputChange}
              className="text-center w-full bg-gray-50 text-md border-none flex items-center text-slate-600 outline-none ring-0 focus:ring-0 focus:outline-none hover:text-black focus:text-slate-700 md:text-basecursor-default disabled:text-slate-400"
            />
            <button
              onClick={increment}
              disabled={disabled}
              data-action="increment"
              className="bg-gray-50 text-slate-400 h-full w-20 cursor-pointer outline-none transition-all duration-300 ease-linear disabled:pointer-events-none hover:text-slate-700 hover:bg-gray-200 disabled:text-slate-200"
            >
              <span className="m-auto text-2xl font-thin">+</span>
            </button>
          </div>
        </div>
        {clearable && (
          <div className="ml-2">
            <button type="button" onClick={() => onChange(null)} className="btn-icon">
              <Icon name="close" />
            </button>
          </div>
        )}
      </div>
      {error && <p className="mt-1 text-xs text-red-600">{errorMessage}</p>}
    </div>
  );
}
