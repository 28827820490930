import Loading from "app/components/atoms/loading";
import { i18n } from "app/i18n";
import { useGenerateTokenMutation } from "app/stores/post";
import { showServerError } from "app/utils/server";
import { useEffect, useState } from "react";
import { Content, Footer, Header, Struture } from "../../../atoms/modal";

export default function ModalToken(props) {
  const { isOpen = false, content, project, type, onClose = () => {} } = props;

  // STATE
  const [info, setInfo] = useState(null);

  // ACTION
  const [getTokenInfo, { isLoading: isGetting }] = useGenerateTokenMutation();

  // VARS
  const isLoading = isGetting || !info;
  const title = type === "preview" ? i18n("label.preview") : type === "rebuild" ? i18n("label.rebuild") : undefined;

  useEffect(() => {
    if (isOpen) getInfo(content, project, type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, content, project, type]);

  const getInfo = () => {
    getTokenInfo({ content, project, type }).then((res) => {
      if (res.data) setInfo(res.data.data);
      else showServerError(res);
    });
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={title} onClose={onClose} />
      <Content>
        <div className="text-center">
          <Loading active={isLoading} size="w-12 h-12" className="mb-4" />
        </div>

        {typeof info?.url === "string" && (
          <>
            <p className="mb-2">Click on link below to see page with draft revisions:</p>
            <a href={info.url} target="_blank" rel="noopener noreferrer" className="btn-primary">
              Preview Page
            </a>
          </>
        )}

        {typeof info?.rebuild === "boolean" && (
          <>
            {info?.rebuild ? (
              <div class="p-4 text-center text-sm text-green-800 rounded-lg bg-green-50 " role="alert">
                <span class="font-medium">Page successfully rebuilt</span>
              </div>
            ) : (
              <div class="p-4 text-center text-sm text-red-800 rounded-lg bg-red-50" role="alert">
                <span class="font-medium">Error on rebuild!</span> Validate if frontend is running or rebuild path is correct.
              </div>
            )}
          </>
        )}
      </Content>
      <Footer loading={isLoading} cancel={{ text: i18n("button.close"), onClick: onClose }} />
    </Struture>
  );
}
